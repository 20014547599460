<template>
  <UiPopup
    :model-value="modelValue"
    :title="`${scheduled ? 'Schedule message' : 'Delete message?'}`"
    :description="`${
      scheduled ? 'Would you like to delete scheduled message?' : 'Are you sure you want to delete the message?'
    }`"
    primary-button-text="Delete"
    secondary-button-text="Cancel"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="confirm"
  />
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  scheduled?: boolean
}

defineProps<Props>()

const confirm = () => {
  emits('input')
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
